import React, { useState } from "react";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "../../../utils/loan-application.styles";
import NumberOfApplicantsStep from "./components/NumberOfApplicantsStep";
import YourDetailsPersonalStatusStep from "./components/YourDetailsPersonalStatusStep";
import YourDetailsCitizenStatusStep from "./components/YourDetailsCitizenStatusStep";
import YourDetailsLoginStep from "./components/YourDetailsLoginStep";
import SectionReviewStep from "./components/SectionReviewStep";

const steps = [
  {
    key: "number-of-applicants",
    title: "Number of Applicants",
    index: 0,
    component: NumberOfApplicantsStep,
    fields: ["numberOfCoApplicants", "coApplicants"],
  },
  {
    key: "your-details-personal-status",
    title: "Your Details",
    index: 1,
    component: YourDetailsPersonalStatusStep,
    fields: ["title", "gender", "maritalStatus"],
  },
  {
    key: "your-details-citizen-status",
    title: "Your Details",
    index: 2,
    component: YourDetailsCitizenStatusStep,
    fields: ["citizenStatus", "dependants"],
  },
  {
    key: "your-details-login-information",
    title: "Your Details",
    index: 3,
    component: YourDetailsLoginStep,
    fields: ["fullName", "email", "password", "mobileNumber", "otp"],
  },
  {
    key: "step-review",
    index: 4,
    component: SectionReviewStep,
  },
];

const AboutYou = ({
  formik,
  onFinishStep,
  tabKey,
  emailsSent,
  onEmailSent,
}) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(steps[0]);

  const handleNextStep = async () => {
    const currentIndex = steps.findIndex(
      (step) => step.key === currentStep.key
    );

    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1]);
    }
  };

  const handlePrevStep = () => {
    const currentIndex = steps.findIndex(
      (step) => step.key === currentStep.key
    );
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1]);
    }
  };

  const handleSaveSection = () => {
    onFinishStep(tabKey);
  };

  const onEdit = () => {
    setCurrentStep(steps[0]);
  };

  return (
    <Box>
      {currentStep.title && (
        <Typography classes={{ root: classes.stepTitle }}>
          {currentStep.title}
        </Typography>
      )}
      <TabContext value={currentStep.key}>
        {steps.map((tab) => (
          <TabPanel
            key={tab.key}
            value={tab.key}
            classes={{
              root: classes.tabPanelRootMobileNoPadding,
            }}
          >
            {tab.component ? (
              <tab.component
                formik={formik}
                onEdit={onEdit}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                handleSaveSection={handleSaveSection}
                emailsSent={emailsSent}
                onEmailSent={onEmailSent}
              />
            ) : null}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default AboutYou;
