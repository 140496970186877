import { Box, Grid } from "@material-ui/core";
import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

import OptionsGroup from "../../components/OptionsGroup";
import {
  titleOptions,
  genderOptions,
  maritalStatusOptions,
} from "../../../../commons/loan-application-constants";
import Button from "../../components/Button";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";

const YourDetailsPersonalStatusStep = ({
  formik,
  handlePrevStep,
  handleNextStep,
}) => {
  const sectionClasses = useSectionStyles();

  const onNextStep = () => {
    formik.setFieldTouched("title");
    formik.setFieldTouched("gender");
    formik.setFieldTouched("maritalStatus");
    if (
      formik.errors.title ||
      formik.errors.gender ||
      formik.errors.maritalStatus
    ) {
      return;
    }
    handleNextStep();
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap={48}>
      <Grid container spacing={8} sm={8} md={6}>
        <OptionsGroup
          title="Title"
          selectedValue={formik.values.title}
          onSelect={(value) => formik.setFieldValue("title", value)}
          options={titleOptions}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <OptionsGroup
          title="Gender"
          selectedValue={formik.values.gender}
          onSelect={(value) => formik.setFieldValue("gender", value)}
          options={genderOptions}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
        />
        <OptionsGroup
          title="Marital Status"
          selectedValue={formik.values.maritalStatus}
          onSelect={(value) => formik.setFieldValue("maritalStatus", value)}
          options={maritalStatusOptions}
          error={
            formik.touched.maritalStatus && Boolean(formik.errors.maritalStatus)
          }
          helperText={
            formik.touched.maritalStatus && formik.errors.maritalStatus
          }
        />
      </Grid>
      <Grid
        container
        xs={12}
        sm={8}
        md={6}
        justifyContent="space-between"
        classes={{
          root: sectionClasses.navigationButtonsContainer,
        }}
      >
        <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
          Previous
        </Button>
        <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
          Next
        </Button>
      </Grid>
    </Box>
  );
};

export default YourDetailsPersonalStatusStep;
