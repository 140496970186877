import {
  Box,
  Container,
  Tab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import { useFormik } from "formik";
import { SnackbarProvider } from "notistack";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import cx from "classnames";

import { useMutation } from "react-query";
import { navigate } from "gatsby";
import {
  useStyles,
  useCommonStyles,
} from "../../utils/loan-application.styles";
import AboutYou from "./about-you";
import Income from "./income";
import Expenses from "./expenses";
import Assets from "./assets";
import Debts from "./debts";
import {
  initialFormValues,
  validationSchema,
} from "../../utils/loan-application.utils";
import CompletedStep from "./completedStep";
import TheLoan from "./the-loan";
import SupportingDocuments from "./supporting-documents";
import LoanApplicationContext from "../../context/loan-application-context";
import { logout } from "../../utils/auth";
import { createDraftScenario } from "../../api/scenario";
import Button from "./components/Button";

const tabs = [
  {
    key: "about-you",
    title: "About You",
    visible: true,
    component: AboutYou,
  },
  {
    key: "income",
    title: "Income",
    visible: true,
    component: Income,
  },
  {
    key: "expenses",
    title: "Expenses",
    visible: true,
    component: Expenses,
  },
  {
    key: "assets",
    title: "Assets",
    visible: true,
    component: Assets,
  },
  {
    key: "debts",
    title: "Debts",
    visible: true,
    component: Debts,
  },
  {
    key: "the-loan",
    title: "The Loan",
    visible: true,
    component: TheLoan,
  },
  {
    key: "supporting-documents",
    title: "Supporting Documents",
    visible: true,
    component: SupportingDocuments,
  },
];

const LoanApplication = () => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [selectedTab, setSelectedTab] = useState("about-you");
  const [editingTabs, setEditingTabs] = useState([]);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isMobileFirstStep, setIsMobileFirstStep] = useState(true);
  const [assetFinishedMap, setAssetFinishedMap] = useState({});
  const [debtFinishedMap, setDebtFinishedMap] = useState({});
  const [emailsSent, setEmailsSent] = useState(new Set());
  const isMobile = useMediaQuery("(max-width:599px)");
  const displayedTabs = tabs.filter((tab) => !completedSteps.includes(tab.key));
  displayedTabs.unshift({
    key: "completed-step",
    title: "Completed",
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: () => {},
  });

  const useCreateDraftScenario = useMutation(
    ({ source, includeOpportunity }) =>
      createDraftScenario(source, includeOpportunity),
    {
      onError: () => {
        navigate("/404");
      },
      onSuccess: (response) => {
        formik.setFieldValue("scenarioId", response.id);
        formik.setFieldValue("opportunityId", response.opportunityId);
      },
    }
  );

  // logout on page intial, and create draft scenario
  useEffect(() => {
    logout(null);
    useCreateDraftScenario.mutate({
      source: "QUICK_LOAN",
      includeOpportunity: true,
    });
  }, []);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onFinishStep = (step) => {
    const currentStepIndex = displayedTabs.findIndex((tab) => tab.key === step);
    const completedStepSet = new Set(completedSteps);
    completedStepSet.add(step);
    setSelectedTab(displayedTabs[currentStepIndex + 1].key);
    setCompletedSteps([...completedStepSet]);
    setEditingTabs(editingTabs.filter((tab) => tab !== step));
  };

  const onEditStep = (step) => {
    const completedStepSet = new Set(completedSteps);
    completedStepSet.delete(step);
    setSelectedTab(step);
    setCompletedSteps([...completedStepSet]);
    setEditingTabs([...editingTabs, step]);
  };

  const setEditing = (key, isEditing) => {
    if (isEditing) {
      if (!editingTabs.includes(key)) {
        setEditingTabs([...editingTabs, key]);
      }
    } else {
      setEditingTabs(editingTabs.filter((tab) => tab !== key));
    }
  };

  const handleAssetFinished = (assetType, isFinished) => {
    setAssetFinishedMap((prev) => ({
      ...prev,
      [assetType]: isFinished,
    }));
  };

  const handleDebtFinished = (debtType, isFinished) => {
    setDebtFinishedMap((prev) => ({
      ...prev,
      [debtType]: isFinished,
    }));
  };

  const handleEmailSent = (email) => {
    setEmailsSent((prev) => {
      const newEmailsSent = new Set(prev);
      newEmailsSent.add(email);
      return newEmailsSent;
    });
  };

  if (isMobile && isMobileFirstStep) {
    return (
      <Box classes={{ root: classes.mobileFirstStep }}>
        <Box classes={{ root: classes.mobileFirstStepTextContainer }}>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize24, commonClasses.fontWeightBold),
            }}
          >
            Quick Online Application, <br /> That Get&apos;s You Answers Fast!
          </Typography>
          <Typography classes={{ root: cx(commonClasses.fontSize18) }}>
            (This does not effect your credit score)
          </Typography>
        </Box>
        <Button
          classes={{
            root: cx(
              commonClasses.fontSize24,
              commonClasses.fontWeightBold,
              commonClasses.textNoTransform,
              classes.mobileFirstStepButton
            ),
            endIcon: commonClasses.textPrimary,
          }}
          variant="outlined"
          onClick={() => setIsMobileFirstStep(false)}
          endIcon={<NavigateNextIcon />}
        >
          Start Application
        </Button>
      </Box>
    );
  }

  return (
    <LoanApplicationContext.Provider value={{ formik }}>
      <SnackbarProvider maxSnack={3}>
        <Container
          maxWidth={false}
          classes={{ root: commonClasses.mobileNoPadding }}
        >
          <TabContext value={selectedTab}>
            <TabList
              onChange={handleChangeTab}
              TabIndicatorProps={{ children: <span /> }}
              variant="scrollable"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.indicator,
              }}
            >
              {displayedTabs.map((tabItem) => {
                if (tabItem.key === "completed-step") {
                  return (
                    <Tab
                      hidden={completedSteps.length === 0}
                      disableRipple
                      key={tabItem.title}
                      fullWidth={false}
                      label={tabItem.title}
                      value={tabItem.key}
                      classes={{ root: classes.tabRoot }}
                    />
                  );
                }
                return (
                  <Tab
                    disableRipple
                    key={tabItem.title}
                    fullWidth={false}
                    label={tabItem.title}
                    value={tabItem.key}
                    classes={{ root: classes.tabRoot }}
                  />
                );
              })}
            </TabList>
            {displayedTabs.map((tabItem) => {
              if (tabItem.key === "completed-step") {
                if (completedSteps.length > 0) {
                  return (
                    <TabPanel
                      key={tabItem.key}
                      value={tabItem.key}
                      index={tabItem.key}
                      classes={{ root: classes.tabPanelRoot }}
                    >
                      <CompletedStep
                        completedItems={completedSteps}
                        onEditStep={onEditStep}
                      />
                    </TabPanel>
                  );
                }
              }
              return (
                <TabPanel
                  key={tabItem.key}
                  value={tabItem.key}
                  index={tabItem.key}
                  classes={{ root: classes.tabPanelRoot }}
                >
                  {tabItem.component ? (
                    <tabItem.component
                      formik={formik}
                      onFinishStep={onFinishStep}
                      tabKey={tabItem.key}
                      isEditing={editingTabs.includes(tabItem.key)}
                      setEditing={(isEditing) =>
                        setEditing(tabItem.key, isEditing)
                      }
                      assetFinishedMap={assetFinishedMap}
                      onAssetFinished={handleAssetFinished}
                      debtFinishedMap={debtFinishedMap}
                      onDebtFinished={handleDebtFinished}
                      emailsSent={emailsSent}
                      onEmailSent={handleEmailSent}
                    />
                  ) : (
                    <div>This feature is currently in development</div>
                  )}
                </TabPanel>
              );
            })}
          </TabContext>
        </Container>
      </SnackbarProvider>
    </LoanApplicationContext.Provider>
  );
};

export default LoanApplication;
