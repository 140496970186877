import React, { useCallback, useMemo } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useMutation } from "react-query";
import { navigate } from "gatsby";

import UploadCard from "../components/UploadCard";
import Button from "../components/Button";
import { useNotification } from "../../../hooks/loan-application.hooks";
import { mapFormValueToParams } from "../../../utils/loan-application.utils";
import {
  createQuickLoan,
  deleteDocument,
  uploadDocument,
} from "../../../api/scenario";
import { useIsLoggedIn } from "../../../hooks/user.hooks.ts";
import { useDocumentListWithURL } from "../../../hooks/loanApplication.hooks";
import { useQueryInvalidationWithNotification } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  applicantContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "48px",
    },
    justifyContent: "center",
  },
  applicantItem: {
    display: "flex",
    flexDirection: "column",
    gap: "48px",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0",
      width: "75%",
      gap: "0",
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0",
      width: "100%",
      gap: "0",
    },
  },
}));

const DOCUMENT_CATEGORY = {
  DRIVER_LICENSE: "Driver license",
  PASSPORT: "Photo ID - Equfax - Info Gather",
  PAYG_INCOME: "PAYG income",
  BANK_STATEMENTS: "Bank statements",
  OTHER: "Uncategorised",
};

const SupportingDocuments = ({ formik }) => {
  const classes = useStyles();
  const isLoggedIn = useIsLoggedIn();
  const { showNotification } = useNotification();
  const invalidateQueries = useQueryInvalidationWithNotification();

  const documentList = useDocumentListWithURL(
    formik.values.scenarioId,
    formik.values.opportunityId
  );
  const documentsByCategory = useMemo(() => {
    const categoryMap = {
      [DOCUMENT_CATEGORY.DRIVER_LICENSE]: [],
      [DOCUMENT_CATEGORY.PASSPORT]: [],
      [DOCUMENT_CATEGORY.PAYG_INCOME]: [],
      [DOCUMENT_CATEGORY.BANK_STATEMENTS]: [],
      [DOCUMENT_CATEGORY.OTHER]: [],
    };

    documentList?.forEach((doc) => {
      const category = doc.category || DOCUMENT_CATEGORY.OTHER;
      if (categoryMap[category]) {
        categoryMap[category].push(doc);
      } else {
        categoryMap[DOCUMENT_CATEGORY.OTHER].push(doc);
      }
    });

    return categoryMap;
  }, [documentList]);

  const uploadDocumentMutation = useMutation(
    ({ params, options }) => {
      return uploadDocument(formik.values.scenarioId, params, options);
    },
    {
      onSuccess: () => {
        showNotification("Document uploaded successfully", "success");
        invalidateQueries("documentList");
      },
      onError: () => {
        showNotification("Document upload failed", "error");
      },
    }
  );

  const deleteDocuments = useMutation(
    ({ selectedScenarioId, documentId }) =>
      deleteDocument(selectedScenarioId, documentId),
    {
      onSuccess: () => {
        invalidateQueries("documentList");
      },
      onError: () => {
        showNotification("Document delete failed", "error");
      },
    }
  );

  const handleDeleteDocument = (documentId) => {
    deleteDocuments.mutate({
      selectedScenarioId: formik.values.scenarioId,
      documentId,
    });
  };

  const createLoanMutation = useMutation(
    ({ scenarioId, payload }) => createQuickLoan(scenarioId, payload),
    {
      onSuccess: (response) => {
        if (response) {
          showNotification(
            "Loan Application Submitted Successfully",
            "success"
          );
          navigate("/");
        }
      },
      onError: () => {
        showNotification(
          "Cannot Submit Loan Application. Please try again.",
          "error"
        );
      },
    }
  );

  const handleUpload = useCallback(
    async (files, type) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("category", type);
      await uploadDocumentMutation.mutateAsync({
        params: formData,
      });
    },
    [uploadDocumentMutation]
  );

  const handleDownload = useCallback(async (file) => {
    if (!file?.url) {
      return;
    }
    const fileURL = file.url.replace(
      "isDownloadRequest=false",
      "isDownloadRequest=true"
    );
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = file.name;
    a.click();
    a.remove();
  }, []);

  const handleSubmitForm = async () => {
    if (
      documentsByCategory[DOCUMENT_CATEGORY.DRIVER_LICENSE].length === 0 &&
      documentsByCategory[DOCUMENT_CATEGORY.PASSPORT].length === 0
    ) {
      showNotification(
        "Either Driver License or Passport must be uploaded",
        "error"
      );
      return;
    }

    if (
      documentsByCategory[DOCUMENT_CATEGORY.BANK_STATEMENTS].length === 0 &&
      documentsByCategory[DOCUMENT_CATEGORY.PAYG_INCOME].length === 0
    ) {
      showNotification(
        "Either Bank Statements or Payslips must be uploaded",
        "error"
      );
      return;
    }

    const error = await formik.validateForm();
    console.debug("submit form error", error, formik.values);
    if (
      !error ||
      Object.keys(error).some(
        (key) =>
          !["loginPassword", "otp", "password", "coApplicants"].includes(key)
      )
    ) {
      showNotification("Please fill in all required fields", "error");
      return;
    }
    createLoanMutation.mutate({
      scenarioId: formik.values.scenarioId,
      payload: mapFormValueToParams(formik.values),
    });
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap="24px">
      {isLoggedIn ? (
        <>
          <Box classes={{ root: classes.applicantContainer }}>
            <Box classes={{ root: classes.applicantItem }}>
              <Box display="flex" flexDirection="column" gridRowGap="16px">
                <UploadCard
                  onUpload={(files) =>
                    handleUpload(files, DOCUMENT_CATEGORY.DRIVER_LICENSE)
                  }
                  maxFiles={2}
                  files={documentsByCategory[DOCUMENT_CATEGORY.DRIVER_LICENSE]}
                  title="Drivers License"
                  onDelete={(file) => handleDeleteDocument(file.id)}
                  onDownload={(file) => handleDownload(file)}
                />
                <UploadCard
                  onUpload={(files) =>
                    handleUpload(files, DOCUMENT_CATEGORY.PASSPORT)
                  }
                  maxFiles={1}
                  files={documentsByCategory[DOCUMENT_CATEGORY.PASSPORT]}
                  title="Passport"
                  onDelete={(file) => handleDeleteDocument(file.id)}
                  onDownload={(file) => handleDownload(file)}
                />
                <UploadCard
                  onUpload={(files) =>
                    handleUpload(files, DOCUMENT_CATEGORY.PAYG_INCOME)
                  }
                  maxFiles={2}
                  files={documentsByCategory[DOCUMENT_CATEGORY.PAYG_INCOME]}
                  title="Two Recent Payslips"
                  onDelete={(file) => handleDeleteDocument(file.id)}
                  onDownload={(file) => handleDownload(file)}
                />
                <UploadCard
                  onUpload={(files) =>
                    handleUpload(files, DOCUMENT_CATEGORY.BANK_STATEMENTS)
                  }
                  files={documentsByCategory[DOCUMENT_CATEGORY.BANK_STATEMENTS]}
                  title="Bank Statements"
                  onDelete={(file) => handleDeleteDocument(file.id)}
                  onDownload={(file) => handleDownload(file)}
                />
                <UploadCard
                  onUpload={(files) =>
                    handleUpload(files, DOCUMENT_CATEGORY.OTHER)
                  }
                  files={documentsByCategory[DOCUMENT_CATEGORY.OTHER]}
                  title="All other documents"
                  onDelete={(file) => handleDeleteDocument(file.id)}
                  onDownload={(file) => handleDownload(file)}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={handleSubmitForm}
              loading={createLoanMutation.isLoading}
            >
              Submit Loan Application
            </Button>
          </Box>
        </>
      ) : (
        <Typography color="error">
          Please completed About You section first to be able to upload
          supporting documents
        </Typography>
      )}
    </Box>
  );
};

export default SupportingDocuments;
